import React from "react";
import { object } from "prop-types";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Layout } from "components/Layout";

export default function ThematicTemplate({ data: { mdx }, location }) {
  return (
    <Layout location={location} title={mdx.frontmatter.pageTitle}>
      <MDXRenderer
        localImages={mdx.frontmatter.localImages}
        localDocuments={mdx.frontmatter.localDocuments}
        localVideos={mdx.frontmatter.localVideos}
      >
        {mdx.body}
      </MDXRenderer>
    </Layout>
  );
}

ThematicTemplate.propTypes = {
  data: object.isRequired,
  mdx: object,
  location: object.isRequired,
};

export const pageQuery = graphql`
  query ThematicQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        localImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        localDocuments {
          publicURL
        }
        localVideos {
          publicURL
        }
        pageTitle
      }
    }
  }
`;
